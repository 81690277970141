import React from "react"
import Layout from "../components/common/layout"
import CartLayout from "../components/cart"
import data from "../data/fields"
import HeadGenerator from "../components/common/seo"

const CartPage = () => {
  return (
    <Layout>
      <CartLayout />
    </Layout>
  )
}

export default CartPage

export const Head = () => (
  <HeadGenerator
    data={{
      title: "Tu carrito en Jockey On Ice",
      description: "¡Revisa tu carrito de compras y completa tu pedido!",
      url: data.url.carrito,
    }}
  />
)
