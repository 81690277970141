import React, { useState, useContext, useMemo, useCallback } from "react"
import debounce from "lodash.debounce"
import { StoreContext } from "../../../context/store-context"
import { getShopifyImage } from "gatsby-source-shopify"
import { Counter } from "../../common/selection"
import { Bin, Loader } from "../../common/icons"
import { getProductUrl, getVariantStock } from "../../../util/data"
import { useEffect } from "react"
import { Link } from "gatsby"

const Item = ({ item }) => {
  const { removeLineItem, updateLineItem, loading } = useContext(StoreContext)
  const [stock, setStock] = useState(0)
  const [variantImage] = useState({
    ...item.variant.image,
    originalSrc: item.variant.image.src,
  })

  useEffect(() => {
    getVariantStock(item.variant).then(stock => setStock(stock))
  }, [item.variant])

  const price = Number(item.variant.priceV2.amount)

  const uli = debounce(value => updateLineItem(item.id, value), 300)
  const debouncedUli = useCallback(value => uli(value), [uli])

  const handleQuantityChange = value => {
    if (isNaN(value)) return
    if (value !== "" && Number(value) < 1) return
    if (Number(value) > stock) return
    if (Number(value) >= 1) {
      debouncedUli(value)
    }
  }

  const image = useMemo(
    () =>
      getShopifyImage({
        image: variantImage,
        layout: "constrained",
        crop: "contain",
        width: 128,
        height: 128,
      }),
    [variantImage]
  )

  return (
    <div className="w-full flex flex-col gap-4 relative">
      <div className="w-full flex items-stretch gap-4 h-min">
        <div>
          <Link
            to={getProductUrl(item.variant.product.handle)}
            className="shrink max-w-[8rem] min-w-[6rem] rounded-lg border border-sky-100 hover:border-sky-500 focus-visible:border-sky-500 transition-colors duration-300 overflow-hidden flex items-center justify-center relative"
          >
            <img
              {...image?.images?.fallback}
              alt={item.title}
              className="w-full h-full object-contain"
            />
            <div
              className={`absolute inset-0 flex justify-center items-center p-4 transition-all duration-300 ${
                loading ? "bg-white/50" : "opacity-0"
              }`}
            >
              <div className="w-12 h-12">
                <Loader />
              </div>
            </div>
          </Link>
        </div>

        <div className="flex flex-col grow gap-1">
          <div className="w-full flex items-center justify-between">
            <span className="font-bold">S/ {price * item.quantity}</span>
            <button
              onClick={() => removeLineItem(item.id)}
              className="text-gray-300 hover:text-red-500 focus-visible:text-red-500 transition-colors duration-300"
            >
              <Bin />
            </button>
          </div>
          <div className="flex flex-col">
            <Link
              to={getProductUrl(item.variant.product.handle)}
              className="hover:text-sky-500 focus-visible:text-sky-500 transition-colors duration-300"
            >
              {item.title}
            </Link>
            {item.variant.title !== "" && item.variant.title !== "Default Title" && (
              <span className="text-gray-400 text-sm">{item.variant.title}</span>
            )}
          </div>
          <div className="grow hidden xs:flex justify-end items-end">
            <div className="grow max-w-[12rem]">
              <Counter
                min={0}
                max={Infinity}
                value={item.quantity}
                setValue={handleQuantityChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="grow flex xs:hidden justify-end">
        <div className="max-w-[12rem]">
          <Counter min={0} max={Infinity} value={item.quantity} setValue={handleQuantityChange} />
        </div>
      </div>
      {loading && <div className="absolute inset-0 cursor-progress"></div>}
    </div>
  )
}
export default Item
