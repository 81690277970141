import { Link } from "gatsby"
import React, { useContext } from "react"
import { useEffect } from "react"
import { useState } from "react"
import { StoreContext } from "../../context/store-context"
import { formatPrice } from "../../util/format"
import data from "../../data/fields"
import { PastelButton } from "../common/button"
import { SimplePaddedCard } from "../common/cards"
import { Loader, Truck } from "../common/icons"
import { CardsLayout, LayoutSection } from "../common/layout"
import Item from "./item"

const CartLayout = () => {
  const { checkout, initialized } = useContext(StoreContext)
  const [goToCheckout, setGoToCheckOut] = useState()
  const [loading, setLoading] = useState()

  useEffect(() => {
    if (!goToCheckout) return
    setLoading(true)
    window.open(checkout.webUrl, "_self")
  }, [goToCheckout, checkout.webUrl])

  return (
    <LayoutSection>
      <section className="w-full flex justify-center p-8">
        <div className="w-full max-w-lg lg:max-w-5xl flex flex-col lg:flex-row justify-center items-start gap-4 lg:gap-6">
          <div className="flex flex-col gap-4 grow">
            <SimplePaddedCard>
              <h2 className="text-4xl font-bold">Mi carrito</h2>
            </SimplePaddedCard>
            <SimplePaddedCard>
              <div className="flex flex-col gap-8 divide-y divide-sky-100">
                {initialized ? (
                  checkout.lineItems.length ? (
                    checkout.lineItems.map((item, index) => (
                      <div className={`w-full ${index ? "pt-8" : ""}`} key={index}>
                        <Item item={item} />
                      </div>
                    ))
                  ) : (
                    <div className="w-full text-center text-sm flex flex-col items-center gap-2">
                      <span>No hay productos en tu carrito</span>
                      <Link to={data.url.tienda} className="text-sky-500 underline">
                        Ir a la tienda
                      </Link>
                    </div>
                  )
                ) : (
                  <div className="w-full flex justify-center">
                    <div className="w-16 h-16">
                      <Loader />
                    </div>
                  </div>
                )}
              </div>
            </SimplePaddedCard>
            <SimplePaddedCard>
              <div className="w-full flex justify-end gap-4">
                <span className="font-bold text-xl">Subtotal</span>
                <span className="font-bold text-xl">
                  S/ {formatPrice(checkout.lineItemsSubtotalPrice?.amount || 0)}
                </span>
              </div>
            </SimplePaddedCard>
            <SimplePaddedCard>
              <div className="flex gap-2 items-center">
                <div className="flex flex-col grow">
                  <div className="flex gap-2 items-basis">
                    <Truck />
                    <h3 className="text-lg font-bold">Entrega a domicilio</h3>
                  </div>
                  <p className="text-sm">
                    Tarifa plana. Las entregas se realizan a nivel nacional en un plazo de 5 - 10
                    días hábiles.
                  </p>
                </div>
                <span className="font-bold text-xl whitespace-nowrap">S/ 20</span>
              </div>
            </SimplePaddedCard>
          </div>
          <div className="flex flex-col gap-4 lg:max-w-sm w-full">
            <SimplePaddedCard>
              <div className="flex flex-col w-full divide-y gap-2">
                <h2 className="text-xl font-bold">Resumen de la orden</h2>
                <div className="w-full flex flex-col gap-4 pt-2 text-sm">
                  <span>Envío a domicilio no incluido</span>
                  <div className="w-full flex flex-col">
                    <div className="flex justify-between w-full">
                      <span>
                        Productos ({checkout.lineItems.reduce((a, b) => a + b.quantity, 0)})
                      </span>
                      <span>S/ {formatPrice(checkout.lineItemsSubtotalPrice?.amount || 0)}</span>
                    </div>
                    <div className="flex justify-between w-full">
                      <span>Envío a domicilio</span>
                      <span>Por definir</span>
                    </div>
                  </div>
                  <div className="w-full flex flex-col">
                    <div className="flex justify-between w-full font-bold text-lg">
                      <span>TOTAL</span>
                      <span>S/ {formatPrice(checkout.lineItemsSubtotalPrice?.amount || 0)}</span>
                    </div>
                  </div>
                  <PastelButton
                    disabled={checkout.lineItems?.length === 0 || loading}
                    onClick={() => setGoToCheckOut(true)}
                  >
                    <div
                      className={`w-full text-center flex justify-center ${
                        checkout.lineItems?.length === 0 ? "line-through text-gray-500" : ""
                      }`}
                    >
                      {loading ? (
                        <div className="flex gap-1 justify-center items-center">
                          <div className="w-6 h-6">
                            <Loader />
                          </div>
                          Cargando
                        </div>
                      ) : (
                        "Continuar con la compra"
                      )}
                    </div>
                  </PastelButton>
                  <div className="w-full flex flex-col gap-2">
                    <h3 className="text-lg font-bold">Pago seguro con</h3>
                    <CardsLayout />
                  </div>
                </div>
              </div>
            </SimplePaddedCard>
          </div>
        </div>
      </section>
    </LayoutSection>
  )
}

export default CartLayout
